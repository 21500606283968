<template>
  <b-card>
    <div class="my-2">
      <div class="row justify-content-end">
        <div class="d-flex align-items-center justify-content-end mb-1 mb-md-0 col-md-4 col-12">
          <b-button
            variant="primary"
            data-action-type="new"
            @click="
              (v) => {
                this.$router.push({ name: 'inventory-new' });
              }
            "
          >
            {{ $t('new') }}
          </b-button>
          <vue-excel-xlsx
            :data="itemsArray"
            :columns="tableColumns"
            :filename="this.$route.name"
            :sheetname="'xlsxSheet'"
            class="btn btn-relief-success ml-1 p-0 ptn-sm"
          >
            <img
              src="@/assets/images/icons/xls.jpg"
              alt=""
              style="height: 39px;width: auto;"
            >
          </vue-excel-xlsx>
          <b-button
            variant="relief-danger"
            class="ml-1 p-0 ptn-sm"
            @click="printDocument"
          >
            <img
              src="@/assets/images/icons/pdf.jpg"
              alt=""
              style="height: 39px;width: auto;"
            >
          </b-button>
        </div>
      </div>
    </div>
    <g-table
      ref="items-table"
      :items="invoiceProvider"
      :columns="tableColumns"
      :is-busy="isTableBusy"
      :noAction="true"
      perPage="25"
      :totalRows="totalRows"
      :createButton="{ visiable: false }"
      :excelButton="{ visiable: false }"
      :pdfButton="{ visiable: false }"
      :searchInput="{ visiable: false }"
    >
      <template
        v-if="hideActions === false"
        #actions="{ item }"
      >
        <div
          class="text-nowrap"
          :set="isConsumed = item.lines.some((i) => i.consumedQuantity)"
        >
          <template v-if="item.transactionType === 'stocktaking' && !item.isComplete" />
          <b-button
            v-if="!item.isComplete"
            v-b-tooltip.hover.top="$t('close')"
            v-permission=" 'closeStocktaking' "
            data-action-type="close"
            variant="flat-primary"
            class="btn-icon"
            size="lg"
            style="margin: 0%;padding: 1%"
            @click=" () => { closeInventory(item) }"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-preview-icon`"
              icon="XOctagonIcon"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="$t('edit')"
            v-permission=" 'editStocktaking' "
            data-action-type="edit"
            variant="flat-secondary"
            class="btn-icon"
            size="sm"
            @click=" () => { editItem(item) }"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-preview-icon`"
              icon="EditIcon"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="$t('print')"
            v-permission=" 'viewStocktaking' "
            variant="flat-success"
            class="btn-icon"
            size="sm"
            @click="print(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-preview-icon`"
              icon="PrinterIcon"
              stroke="green"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="$t('delete')"
            v-permission=" 'deleteStocktaking' "
            data-action-type="delete"
            variant="flat-danger"
            class="btn-icon"
            size="sm"
            @click="(v) => { remove(item) }"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-delete-icon`"
              icon="TrashIcon"
              stroke="red"
              class="danger"
            />
          </b-button>
        </div>
      </template>
    </g-table>
  </b-card>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.5.3/jspdf.min.js"></script>
<script src="@/pages/Shared/html2canvas.js"></script>
<script>
import GTable from '@/pages/Shared/Table.vue';
import reportMixin from '@/mixin/reportMixin';

export default {
  components: {
    GTable,
  },
   mixins: [reportMixin],
  data() {
    return {
      filter: {
        transactionType: '',
        id: null,
        dealerId: null,
        storeId: null,
        ItemId: null,
        FromDate: null,
        toDate: null,
        orderClause: null,
        // category: 'stock',
      },
      isTableBusy: false,
      items: [],
      totalRows: 0,
      itemsArray: [],
      hideActions: false
    };
  },
  computed: {
    tableColumns() {
      return [
        {
          key: 'code',
          field: 'code',
          label: this.$t('code'),
          sortable: true,
        },
        {
          key: 'transactionDate',
          field: 'transactionDate',
          label: this.$t('transactionDate'),
          sortable: true,
        },
        {
          key: this.isRight ? 'storeArabicName' : 'storeEnglishName',
          field: this.isRight ? 'storeArabicName' : 'storeEnglishName',
          label: this.$t('store'),
          sortable: true,
        },
        {
          key: 'status',
          field: 'status',
          label: this.$t('status'),
          sortable: false,
          formatter: (key, value, tanx) => {
            if (tanx.isComplete) return `<span class="badge bg-success bg-darken-1">${this.$t('closed')}</span>`;
            return `<span class="badge border-0 bg-primary bg-darken-1">${this.$t('addNew')}</span>`;
          },
        },
        {
          key: 'actions',
        },
      ];
    },
  },
  methods: {
    closeInventory(item) {
      this.confirmAction(
        {
          text: this.$t('areYouSureYouWantToClose'),
          confirmText: this.$t('yes')
        },
        () => {
          this.create({ url: `ItemTransactions/Stocktaking/${item.id}/actions/close` }).then(() => {
          this.doneAlert({ text: this.$t('closedSuccessfully') });
          this.$router.push({ name: 'inventory' });
          location.reload();
          });
        }
      );
    },
    hide() {
      this.hideActions = true
    },
    pdfExport(name) {
      html2canvas(document.getElementById('mainTable')).then(function(canvas){
      var imgData = canvas.toDataURL('image/png');
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      var doc = new jsPDF('p', 'mm');
      var position = 0;

      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
          doc.save(`${name}.pdf`);
      });
    },
    printDocument() {
      this.hide()
        setTimeout(() => {this.pdfExport(this.$route.name)
        this.hideActions = false
        }, 500);
    },
    remove(item) {
      this.confirmAction(
        {
          text: this.$t('areYouSureYouWantToDelete'),
        },
        () => {
          this.delete({ url: `ItemTransactions/${item.transactionType}`, id: item.id }).then(() => {
            this.doneAlert({ text: this.$t('deletedSuccessfully') });
            this.$refs['items-table'].refreshTable();
          });
        }
      );
    },
    editItem(item) {
      this.$router.push({
        name: 'inventory-edit',
        params: { id: item.id },
      });
    },
    invoiceProvider(ctx, callback) {
      const {
        currentPage, perPage, sortBy, sortDesc
      } = ctx;

      this.filter.OrderClause = this.orderQuery(sortBy, sortDesc);
      let params = `?pageNumber=${currentPage}&pageSize=${perPage}&`;

      params += this.getFilterObj(this.filter);

      this.isTableBusy = true;
      this.get({ url: `itemTransactions/stocktaking${params}` })
        .then(({ data, totalCount }) => {
          this.isTableBusy = false;
          this.totalRows = totalCount;
          data.forEach((item) => {
            item.transactionDate = this.getDate(item.transactionDate);
          });
          callback(data);
          this.itemsArray = data;
        })
        .catch(() => {
          this.isTableBusy = false;
          callback([]);
        });
      return null;
    },
     print(item) {
      const printedItem = {
        id: item.id
      }
       this.printReport('Stocktaking-ar', printedItem);
    },
  },
};
</script>
<style></style>
